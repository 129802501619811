import React, { useState } from 'react';
import axios from 'axios';
import './ComingSoonPage.css';

const ComingSoonPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [artistName, setArtistName] = useState('');
    const [instagramHandle, setInstagramHandle] = useState('');
    const [genreHandle, setGenreHandle] =useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!role) {
            setMessage('Please select either Artist or Fan.');
            return;
        }

        let payload = {
            email,
            attributes: { FIRSTNAME: name },
            listIds: [3],
            updateEnabled: true,
        };

        if (role === 'artist') {
            payload = {
                email,
                attributes: { FIRSTNAME: name,  INSTAGRAME: instagramHandle,  },
                listIds: [7], // Replace with the appropriate list ID for artists
                updateEnabled: true,
            };
        }

        console.log('Sending request with payload:', payload);

        try {
            const response = await axios.post(
                'https://api.brevo.com/v3/contacts',
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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', // Replace with your API key
                    },
                }
            );
            console.log('Response:', response.data);
            setMessage('Thank you! Your information has been successfully submitted.');
        } catch (error) {
            console.error('Error response:', {
                status: error.response?.status,
                data: error.response?.data,
            });
            setMessage('Error: Unable to send your information. Please try again later.');
        }
    };

    return (
        <div className="coming-soon-page">
            <div className="content">
                <div className="logo">
                    <img src={require('./assets/LOGO_FULL_WHITE.png')} alt="Logo" />
                </div>
                <div className="heading">
                    <h1>Coming Soon</h1>
                    <p>Be the first to experience <b><i>EXVERSIO</i></b></p>

                </div>

                <form className="email-form" onSubmit={handleSubmit}>
    <div className="role-selection">
    <label>
            <input
                type="radio"
                name="role"
                value="fan"
                checked={role === 'fan'}
                onChange={(e) => setRole(e.target.value)}
            />
            🎧 <b><i>Fans:</i></b>Sign up for early access to exclusive music
        </label>
        <label>
            <input
                type="radio"
                name="role"
                value="artist"
                checked={role === 'artist'}
                onChange={(e) => setRole(e.target.value)}
            />
            🎤 <b><i>Artists:</i></b>Join the platform and share your unreleased tracks
        </label>
       
    </div>

    <input
        type="text"
        placeholder={role === 'artist' ? 'Artist Name' : 'Your Name'}
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
    />
    <input
        type="email"
        placeholder="Email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
    />

    {role === 'artist' && (
        <>
            
            <input
                type="text"
                placeholder="Instagram Username"
                value={instagramHandle}
                onChange={(e) => setInstagramHandle(e.target.value)}
                required
            />
           
        </>
    )}

    <button type="submit">Submit</button>
</form>

                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default ComingSoonPage;
